import React from 'react'
import styled from 'styled-components'
import { FaExternalLinkAlt } from "react-icons/fa"
import { useStaticQuery, graphql } from 'gatsby'

import Seo from '../components/seo'
import Layout from '../layouts/layout'
import ContentContainer from '../components/UI/ContentContainer'
import { URL } from '../support'
import { parseJson } from '../support'

const WalletTermsAndConditions = ({ path }) => {

  const queriedStructuredDataConfigForWallet = useStaticQuery(graphql`
    query structuredDataConfigForWalletQuery {
      contentfulComponentStructuredDataConfig (
        page: { eq: "wallet-terms-and-conditions" }
      ) {
        page
        structuredDataConfig {
          internal {
            content
          }
        }
      }
    }
  `)
  const structuredData = parseJson(queriedStructuredDataConfigForWallet.contentfulComponentStructuredDataConfig.structuredDataConfig?.internal?.content)
  
  return (
    <Layout hasGetYourRate={false}>
      <Seo
        title={"Wallet Terms And Conditions"}
        path={path}
        structuredData={structuredData}
      />
      <ContentContainer>
        <Wrapper>
          <h1>Wallet Terms & Conditions</h1>
          <Question href={URL.APPLE_PAY_TERMS_AND_CONDITIONS} target="_blank"> 
						<div className="icon">
							<FaExternalLinkAlt />
						</div>
						<p>Apple Pay Terms and Conditions</p>
					</Question>
					<Question href={URL.GOOGLE_PAY_TERMS_AND_CONDITIONS} target="_blank">
						<div className="icon">
							<FaExternalLinkAlt />
						</div>
						<p>Google Pay Terms and Conditions</p>
					</Question>
          <Content>
            <p>SpendOne and SaveOne accounts are financial products brought to you by SocietyOne Australia Pty Ltd (ABN 44 151 627 977, Authorised Representative no. 425717 of SocietyOne Investment Management Pty Ltd ACN 604 960 018 AFSL 477365). Accounts and debit cards are issued by Westpac Banking Corporation (ABN 33 007 457 141, AFSL 233714).</p>
            <p>Apple and the Apple logo are trademarks of Apple Inc., registered in the U.S. and other countries.</p>
            <p>App Store is a service mark of Apple Inc. Google Pay is a trademark of Google LLC.</p>
          </Content>
        </Wrapper>
      </ContentContainer>
    </Layout>  )
}

const Wrapper = styled.div`
	display: flex;
  flex-direction: column;
	align-items: center;
  justify-content: center;
	h1 {
		text-align: center; 
    margin-bottom: 40px;
		@media screen and (max-width: 991px) {
			font-size: 42px;
		};
	};
  
`
const Question = styled.a`
	display: flex;
	align-items: center;
  justify-content: center;
  &:hover {
    color: var(--text-color);

  }

	p {
		font-weight: 900;
    margin: 0;
    padding: 18px 0;
    &:hover {
      cursor: pointer;
      color: var(--text-color);
      text-decoration: underline;
    }
	};
	.icon {
		margin: 0 10px; 
		display: flex; 
		align-items: center;
	};
	@media screen and (max-width: 768px) {
    p {
      font-size: 18px !important;
    };
	}
`
const Content = styled.div`
  margin-top: 90px;
  @media screen and (max-width: 768px) {
    margin-top: 50px;
  };
  p {
    font-size: 14px;
  };
`
export default WalletTermsAndConditions